import React from 'react'
import { Link } from 'react-router-dom'
import MinSlideSection from '../sections/MinSlideSection';
import HeaderComponent from '../components/header/HeaderComponent';

export default function HomePage() {
  return (
    <section>
      <HeaderComponent />
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="content">
              <div className="info">
                <h2>
                  THE HUB
                  <br />
                  FOR <span>UNLOCKING</span>
                  <br />
                  TRUE POTENTIAL <br />
                </h2>
                <Link to="#" className="info-btn">
                  Button
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="card">
              <div className="slide">
                <MinSlideSection />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
