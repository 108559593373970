import React from 'react'
import { Link } from 'react-router-dom'

export default function HeaderComponent() {
    return (
        <header>
            <h2>
                <Link to="#" className="logo">
                    Chaabi
                </Link>
            </h2>
            <div className="navigation">
                <Link to="#">Build</Link>
                <Link to="#">Engage</Link>
                <Link to="#">Grow</Link>
                <Link to="#">Resources</Link>
                <Link to="#">Pricing</Link>
                <button type="button" className="btn btn-outline-light me-1">
                    Login
                </button>
                <button type="button" className="btn btn-outline-light">
                    Try Chaabi for free
                </button>
            </div>
            <label htmlFor="check">
                <i className="fas fa-bars menu-btn" />
                <i className="fas fa-times close-btn" />
            </label>
        </header>
    )
}
