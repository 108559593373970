import React, { useState } from 'react';
const SlideImages=['Image 1', 'Image 2', 'Image 3']
export default function MinSlideSection() {
    const [activeIndex, setActiveIndex] = useState(0);
    const handleMouseEnter = (index) => {
        setActiveIndex(index);
    };

    const handleMouseLeaveImage = (index) => {
        setActiveIndex(index);
    };

    return (
        <>
            {SlideImages.map((image, index) => (
                <div
                    key={index}
                    className={`image ${activeIndex === index ? 'active' : ''}`}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={()=>handleMouseLeaveImage(index)}
                >
                    {image}
                </div>
            ))}
        </>
    );
};

